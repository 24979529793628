<template>
    <div class="face-id-icon">
        <svg class="face-id-icon__svg" :width="size" :height="size" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 28 28">
            <path d="M10.05 28H5C4.73478 28 4.48043 27.8946 4.29289 27.7071C4.10536 27.5196 4 27.2652 4 27V21.9C4 21.6348 3.89464 21.3804 3.70711 21.1929C3.51957 21.0054 3.26522 20.9 3 20.9C2.73478 20.9 2.48043 21.0054 2.29289 21.1929C2.10536 21.3804 2 21.6348 2 21.9V27C2 27.7956 2.31607 28.5587 2.87868 29.1213C3.44129 29.6839 4.20435 30 5 30H10.05C10.3152 30 10.5696 29.8946 10.7571 29.7071C10.9446 29.5196 11.05 29.2652 11.05 29C11.05 28.7348 10.9446 28.4804 10.7571 28.2929C10.5696 28.1054 10.3152 28 10.05 28ZM29 20.9C28.7348 20.9 28.4804 21.0054 28.2929 21.1929C28.1054 21.3804 28 21.6348 28 21.9V27C28 27.2652 27.8946 27.5196 27.7071 27.7071C27.5196 27.8946 27.2652 28 27 28H21.95C21.6848 28 21.4304 28.1054 21.2429 28.2929C21.0554 28.4804 20.95 28.7348 20.95 29C20.95 29.2652 21.0554 29.5196 21.2429 29.7071C21.4304 29.8946 21.6848 30 21.95 30H27C27.7956 30 28.5587 29.6839 29.1213 29.1213C29.6839 28.5587 30 27.7956 30 27V21.9C30 21.6348 29.8946 21.3804 29.7071 21.1929C29.5196 21.0054 29.2652 20.9 29 20.9ZM3 11C3.26522 11 3.51957 10.8946 3.70711 10.7071C3.89464 10.5196 4 10.2652 4 10V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H10.05C10.3152 4 10.5696 3.89464 10.7571 3.70711C10.9446 3.51957 11.05 3.26522 11.05 3C11.05 2.73478 10.9446 2.48043 10.7571 2.29289C10.5696 2.10536 10.3152 2 10.05 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11ZM27 2H21.95C21.6848 2 21.4304 2.10536 21.2429 2.29289C21.0554 2.48043 20.95 2.73478 20.95 3C20.95 3.26522 21.0554 3.51957 21.2429 3.70711C21.4304 3.89464 21.6848 4 21.95 4H27C27.2652 4 27.5196 4.10536 27.7071 4.29289C27.8946 4.48043 28 4.73478 28 5V10C28 10.2652 28.1054 10.5196 28.2929 10.7071C28.4804 10.8946 28.7348 11 29 11C29.2652 11 29.5196 10.8946 29.7071 10.7071C29.8946 10.5196 30 10.2652 30 10V5C30 4.20435 29.6839 3.44129 29.1213 2.87868C28.5587 2.31607 27.7956 2 27 2Z" fill="currentColor"></path>
            <path d="M12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14Z" fill="currentColor"></path>
            <path d="M19.5 14C20.3284 14 21 13.3284 21 12.5C21 11.6716 20.3284 11 19.5 11C18.6716 11 18 11.6716 18 12.5C18 13.3284 18.6716 14 19.5 14Z" fill="currentColor"></path>
            <path d="M21.724 18.311C21.5412 18.1191 21.2897 18.0077 21.0247 18.0011C20.7598 17.9945 20.5031 18.0934 20.311 18.276C19.1487 19.383 17.6051 20.0004 16 20.0004C14.3949 20.0004 12.8513 19.383 11.689 18.276C11.5938 18.1855 11.4817 18.1147 11.3591 18.0675C11.2365 18.0204 11.1058 17.9978 10.9745 18.0012C10.8432 18.0045 10.7139 18.0337 10.5938 18.087C10.4738 18.1404 10.3655 18.2168 10.275 18.312C10.1845 18.4072 10.1137 18.5193 10.0665 18.6419C10.0194 18.7645 9.9968 18.8952 10.0001 19.0265C10.0035 19.1578 10.0327 19.2871 10.086 19.4072C10.1393 19.5272 10.2158 19.6355 10.311 19.726C11.8448 21.1868 13.8818 22.0016 16 22.0016C18.1182 22.0016 20.1552 21.1868 21.689 19.726C21.7843 19.6355 21.8608 19.527 21.9142 19.4068C21.9675 19.2867 21.9967 19.1572 21.9999 19.0258C22.0032 18.8943 21.9805 18.7636 21.9331 18.6409C21.8858 18.5183 21.8147 18.4062 21.724 18.311Z" fill="currentColor"></path>
        </svg>
        <div v-if="scanning" class="face-id-icon__bar"></div>
    </div>
</template>

<script>
export default {
    name: 'FaceId',
    props: {
        size: {
            type: Number,
            default: 32,
            required: false,
        },
        scanning: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.face-id-icon {
    position: relative;

    &__bar {
        background-color: rgba($accounts-primary, 0.5);
        border-radius: 4px;
        height: 6px;
        width: 100%;
        

        position: absolute;
        top: calc(50% - 3px);
        animation: 3s scaning linear infinite;

        @keyframes scaning {
            0% {
                top: calc(50% - 3px);
            }

            25% {
                top: calc(2% - 3px);
            }

            75% {
                top: calc(98% - 3px);
            }
        }
    }
}
</style>
