<template>
    <div class="ui-vertical-tabs">
        <div class="ui-vertical-tabs__sidebar">
            <ul class="ui-vertical-tabs__nav">
                <li
                    v-for="(tab, index) in tabs"
                    :key="tab.title"
                    class="ui-vertical-tabs__nav-item"
                    :class="{ 'ui-vertical-tabs__nav-item--selected': (index === selectedIndex) }"
                    @click="selectTab(index)"
                >
                    {{ tab.title }}
                </li>
            </ul>
        </div>
        <div class="ui-vertical-tabs__tabs-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    provide() {
        return {
            getTabsInstance: () => this,
        };
    },
    data () {
        return {
            selectedIndex: 0,
            tabs: [],
        };
    },
    mounted() {
        this.selectTab(0);
    },
    methods: {
        selectTab(i) {
            this.selectedIndex = i;

            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);
            });
        },
        registerTab(tab) {
            if (this.tabs.indexOf(tab) === -1) {
                this.tabs.push(tab);
            }
        },
    },
};
</script>

<style lang="scss">
.ui-vertical-tabs {
    display: flex;
    gap: 24px;

    &__sidebar {
        width: 130px;
        border-right: 1px solid #D9D9D9;
        flex-shrink: 0;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        gap: 12px;
        list-style: none;
        padding: 0;
    }

    &__nav-item {
        align-self: flex-start;
        background-color: transparent;
        border-radius: 8px;
        color: #666A97;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        height: 32px;
        max-width: 100%;
        padding: 8px;
        width: auto;

        &--selected {
            background-color: #D0F0F1;
            color: #0E878C;
        }
    }

    &__tabs-container {
        width: 100%;
    }
}
</style>
