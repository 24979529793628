<template>
    <div class="dashboard-profile_page">
        <p class="dashboard-profile_page__text">
            {{ $t('Manage your personal, companies and security information for a better experience.') }}
        </p>
        <div class="row">
            <div class="col-md-12">
                <ui-card>
                    <ui-vertical-tabs>
                        <ui-vertical-tab title="My profile">
                            <div class="dashboard-profile_page__tab-content">
                                <profile-picture-selector />
                                <personal-information-card />
                            </div>
                        </ui-vertical-tab>
                        <ui-vertical-tab title="Security">
                            <div class="dashboard-profile_page__tab-content">
                                <two-factor-authentication-card />
                                <biometric-authentication-card />
                                <web-authn-card />
                            </div>
                        </ui-vertical-tab>
                    </ui-vertical-tabs>
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import BiometricAuthenticationCard from '@/components/profile/BiometricAuthenticationCard.vue';
import PersonalInformationCard from '@/components/profile/PersonalInformationCard.vue';
import ProfilePictureSelector from '@/components/profile/ProfilePictureSelector.vue';
import TwoFactorAuthenticationCard from '@/components/profile/TwoFactorAuthenticationCard.vue';
import WebAuthnCard from '@/components/profile/WebAuthnCard.vue';
import UiCard from '@/components/ui/Card.vue';
import UiVerticalTabs from '@/components/ui/vertical-tabs/VerticalTabs.vue';
import UiVerticalTab from '@/components/ui/vertical-tabs/VerticalTab.vue';

export default {
    name: 'dashboard-profile_page',
    components: {
        BiometricAuthenticationCard,
        PersonalInformationCard,
        ProfilePictureSelector,
        TwoFactorAuthenticationCard,
        WebAuthnCard,
        UiCard,
        UiVerticalTabs,
        UiVerticalTab,
    },
};
</script>

<style lang="scss">
.dashboard-profile_page {
    &__tab-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
</style>
