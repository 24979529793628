<template>
    <div>
        <list-group-skeleton v-if="loading" :rows="3" />
        <list v-else>
            <list-item v-for="auth in auths" :key="auth.id">
                <device-item ref="deviceItem" :auth="auth" @deleteApp="openDeleteDeviceModal" />
            </list-item>
            <list-item v-if="auths.length === 0">
                <p class="biometric-authentication-card__empty-message text-muted">
                    <span class="fas fa-info-circle"></span>
                    {{ $t('You do not yet have devices linked for this authentication method.') }}
                </p>
            </list-item>
        </list>
        <add-face-modal ref="addFaceModal" @faceDetected="faceDetected" />
        <delete-device-modal ref="DeleteDeviceModal" @appDeleted="appDeleted"/>
    </div>
</template>


<script>
import DeviceItem from '@/components/authentication/face/DeviceItem.vue';
import AddFaceModal from '@/components/authentication/face/AddFaceModal.vue';
import DeleteDeviceModal from '@/components/authentication/face/DeleteDeviceModal.vue';
import ListGroupSkeleton from '@/components/ui/skeletons/ListGroupSkeleton.vue';
import List from '@/components/ui/list/List';
import ListItem from '@/components/ui/list/ListItem';

export default {
    name: 'DeviceList',
    components: {
        List,
        ListItem,
        DeviceItem,
        AddFaceModal,
        DeleteDeviceModal,
        ListGroupSkeleton,
    },
    data() {
        return {
            loading: false,
            auths: [],
        };
    },
    mounted() {
        this.getFace();
    },
    methods: {
        openAddFaceModal() {
            this.$refs.addFaceModal.show();
        },
        openDeleteDeviceModal(app) {
            this.$refs.DeleteDeviceModal.openModal(app);
        },
        async faceDetected(data_descriptors) {
            try {
                const { data } = await this.axiosAccount.post('/api/biometric-auth/register', {
                    type: 'face',
                    data: [data_descriptors],
                });

                this.$emit('update:showButton', false);
                this.auths.push(data.doc)
                this.$refs.addFaceModal.hide();
                this.$toasted.global.successMessage('Biometric authentication registered successfully.');
            } catch (err) {
                this.$toasted.global.errorMessage('Biometric authentication registration failed.');
                this.$refs.addFaceModal.faceDetectedEmitted = false;
                this.$refs.addFaceModal.hide();
            }
        },
        appDeleted(app) {
            const search_index = this.auths.findIndex(item => item.id === app.id);
            this.auths.splice(search_index, 1);
            this.$emit('update:showButton', true);
        },
        async getFace() {
            try {
                this.loading = true;
                const { data } = await this.axiosAccount.get('/api/biometric-auth/auth/face');
                this.auths = data;
                
                if(this.auths.length) {
                    this.$emit('update:showButton', false);
                } 
            } catch (error) {
                this.$error(error);
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>