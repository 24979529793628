<template>
    <ui-modal
        ref="deleteApp"
        centered
        :title="$t('Delete Face ID')"
        @hidden="hidden()"
    >
        <p>{{ $t('Are you sure you want to delete this face?') }}</p>
        <p>{{ $t('Please note that by doing so, you will lose an additional layer of security on your account.') }}</p>
        <template #footer>
            <ui-button variant="secondary" :disabled="loading" @click="$refs.deleteApp.close()">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button variant="primary" :loading="loading" @click="deleteDevice">
                {{ $t('Delete') }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

export default {
    name: 'DeleteApp',
    components: { UiButton, UiModal },
    data() {
        return {
            loading: false,
            auth: {},
        }
    },
    methods: {
        openModal(app) { 
            this.auth = app;
            this.$refs.deleteApp.show();
        },
        hidden() {
            this.$refs.deleteApp.close();
        },
        async deleteDevice() {
            try {
                this.loading = true;
                await this.axiosAccount.delete(`/api/biometric-auth/remove/${this.auth.id}`);
                this.$emit('appDeleted', this.auth);
                this.$toasted.global.successMessage(this.$t('Face successfully removed'));
            } catch(err) {
                this.$toasted.global.errorMessage(this.$t('An error occurred while deleting the face, please try again'));
            } finally {
                this.loading = false;
                this.hidden();
            }
        }
    }
}
</script>