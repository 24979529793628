<template>
    <ui-modal ref="addFaceModal" class="add-face-modal" :title="$t('Facial recognition')" centered @hidden="handleHidden" @hide="handleHide">
        <div v-show="!loadingCamera && !mediaError && loginAttempts < maxLoginAttempts">
            <div v-show="showCamera" class="add-face-modal__video-container">
                <div class="add-face-modal__corner add-face-modal__corner--top-left"></div>
                <div class="add-face-modal__corner add-face-modal__corner--top-right"></div>
                <div class="add-face-modal__corner add-face-modal__corner--bottom-right"></div>
                <div class="add-face-modal__corner add-face-modal__corner--bottom-left"></div>
                <video ref="video" class="add-face-modal__video" preload="metadata" autoplay webkit-playsinline playsinline></video>
            </div>
            <div v-show="!showCamera" class="add-face-modal__scanning-icon">
                <face-id-icon :size="130" scanning />
            </div>
        </div>
        <template v-if="loadingCamera">
            <div class="py-5 text-center">
                <span class="fas fa-spinner fa-spin"></span>
            </div>
        </template>
        <template v-else>
            <div v-if="loginAttempts >= maxLoginAttempts">
                <ui-empty-state img-size="sm" img="face-recognition-error.svg" text="We could not validate your face." />
            </div>
            <template v-else>
                <template v-if="mediaError">
                    <ui-empty-state img="no-camera.svg" :text="$t(mediaError)" />
                </template>
                <template v-else>
                    <h6 class="add-face-modal__title"> 
                        {{ $t('Scanning your face...') }}
                    </h6>
                    <p class="add-face-modal__text"> {{ $t('Keep your face centered on the screen and looking forward') }} </p>
                </template>
            </template>
        </template>
        <div class="add-face-modal__footer">
            <button v-if="loginAttempts >= maxLoginAttempts" class="add-face-modal__button" @click="retry">
                {{ $t('Retry') }}
            </button>
            <button v-else class="add-face-modal__button" @click="$refs.addFaceModal.close()">
                {{ $t('Cancel') }}
            </button>
        </div>
    </ui-modal>
</template>

<script>
import * as faceapi from 'face-api.js';

import FaceIdIcon from '@/components/icons/FaceId.vue';
import UiEmptyState from '@/components/ui/EmptyState.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

import mediaDeviceUtils from '@/utils/media-device.utils';

export default {
    name: 'AddFaceModal',
    components: { FaceIdIcon, UiEmptyState, UiModal },
    props: {
        showCamera: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            loginAttempts: 0,
            loadingCamera: true,
            loadingModels: false,
            maxLoginAttempts: 5,
            mediaError: null,
            display_size : { width: 0, height: 0 },
            blinking: false,
            maximunEAR: 0.3,
            nowBlinking: false,
            blinkingCounter: 0,
            faceDetectedEmitted: false,
        }
    },
    mounted() {
        this.loadModels()
    },
    methods: {
        show() {
            this.$refs.addFaceModal.show();
            this.startCamera();
        },
        hide() {
            this.$refs.addFaceModal.close();
            this.handleHide();
        },
        handleHide() {
            const tracks = this.$refs?.video?.srcObject?.getVideoTracks();

            if(tracks?.length) {
                tracks.forEach(track => track.stop());
            }
        },
        handleHidden() {
            this.mediaError = null;
            this.loginAttempts = 0;
        },
        retry() {
            this.loginAttempts = 0;
            this.blinkingCounter = 0;
            this.faceDetectedEmitted = false;
            this.startCamera();
        },
        retryGetFace() {
            setTimeout(() => {
                this.getFace();
            }, 2000);
        },
        async loadModels() {
            try {
                this.loadingModels = true;

                Promise.all([
                    faceapi.loadFaceExpressionModel('/models'),
                    faceapi.loadFaceLandmarkModel('/models'),
                    faceapi.loadFaceRecognitionModel('/models'),
                    faceapi.loadSsdMobilenetv1Model('/models'),
                ]);
                
            } catch (error) {
                this.showError(error);
            } finally {
                this.loadingModels = false
            }
        },
        async startCamera() {
            try {
                this.loadingCamera = true;

                const stream = await navigator.mediaDevices.getUserMedia({ video: true });                
                const video = this.$refs.video;

                video.srcObject = stream;
                video.play();
                this.$refs.video.addEventListener('loadedmetadata', async () => {
                    await this.getFace();
                });
            } catch (error) {
                this.mediaError = mediaDeviceUtils.getErrorMessage(error);
            } finally {
                this.loadingCamera = false;
            }
        },
        async getFace() {
           try {
            
            if (this.loginAttempts >= this.maxLoginAttempts) {
                return;
            }
            
            const video = this.$refs.video;
            
            if (video.paused || video.ended || this.loadingModels) {
                setTimeout(() => {
                    this.getFace();
                }, 1000);
                return;
            }
            
            const face_detected = await faceapi.detectSingleFace(video).withFaceLandmarks().withFaceDescriptor();
            let data_descriptors = null;
            
            if (face_detected) {
                data_descriptors = face_detected.descriptor;
            }
            
            const EAR = await this.calculateEAR(face_detected.landmarks);
            if(EAR < this.maximunEAR) {
                this.blinkingCounter += 1;
            }

            if(this.blinkingCounter > 0 && !this.faceDetectedEmitted) {
                this.faceDetectedEmitted = true;
                this.$emit('faceDetected', data_descriptors);
                return;
            }
            
            this.loginAttempts += 1;
            setTimeout(() => {
                this.getFace();
            }, 2000);
           } catch (error) {
               this.retryGetFace();
           }
        },
        async calculateEAR(landmarks) {
            const left_eye = landmarks.getLeftEye();
            const right_eye = landmarks.getRightEye();

            const left_ear = await this.calculateEyeAspectRatio(left_eye);
            const right_ear = await this.calculateEyeAspectRatio(right_eye);

            return (left_ear + right_ear) / 2;
        },
        async calculateEyeAspectRatio(eye) {
            const a = this.euclideanDistance(eye[1], eye[5]);
            const b = this.euclideanDistance(eye[2], eye[4]);
            const c = this.euclideanDistance(eye[0], eye[3]);

            return (a + b) / (2.0 * c);
        },
        euclideanDistance(a, b) {
            return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
        },
    },
}
</script>

<style lang="scss"> 
.add-face-modal {
    &__button {
        align-items: center;
        background-color: transparent;
        border: 1px solid #00989E;
        border-radius: 8px;
        color: #00989E;
        display: inline-flex;
        flex-shrink: 0;
        gap: 8px;
        height: 32px;
        padding: 8px 16px;

        &:hover {
            background-color: #00989E;
            color: $white;
        }
    }

    &__title {
        color: $general-black;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 8px;
        text-align: center;
    }

    &__text {
        color: #9d9d9d;
        font-weight: 400;
        margin: 0 auto 16px auto;
        max-width: 280px;
        text-align: center;
    }

    &__scanning-icon {
        display: flex;
        justify-content: center;
        color: $accounts-secondary-100;
        margin-bottom: 16px;
    }

    &__video-container {
        align-items: center;
        background-color: #eeeeee;
        border-radius: 16px;
        display: flex;
        height: 240px;
        justify-content: center;
        margin: 0 auto 12px auto;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        width: 200px;
    }

    &__video {
        width: auto;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__corner {
        height: 20px;
        width: 20px;
        position: absolute;
        animation: flickerAnimation 1.6s infinite;

        @keyframes flickerAnimation {
            0% {
                opacity:1;
            }

            50% {
                opacity:0.2;
            }

            100% {
                opacity:1;
            }
        }

        &--top-left {
            border-left: 2px solid $white;
            border-top: 2px solid $white;
            border-top-left-radius: 8px;
            left: 8px;
            top: 8px;
        }
    
        &--top-right {
            border-right: 2px solid $white;
            border-top: 2px solid $white;
            border-top-right-radius: 8px;
            right: 8px;
            top: 8px;
        }
    
        &--bottom-right {
            border-right: 2px solid $white;
            border-bottom: 2px solid $white;
            border-bottom-right-radius: 8px;
            right: 8px;
            bottom: 8px;
        }
    
        &--bottom-left {
            border-left: 2px solid $white;
            border-bottom: 2px solid $white;
            border-bottom-left-radius: 8px;
            left: 8px;
            bottom: 8px;
        }
    }

    &__footer {
        text-align: center;
    }
}
</style>