<template>
    <div class="ui-vertical-tab" v-if="isActive">
        <h6 class="ui-vertical-tab__title">{{ title }}</h6>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Tab title',
            require: false,
        },
    },
    inject: {
        getTabsInstance: { default: () => () => ({}) },
    },
    computed: {
        tabsInstance() {
            return this.getTabsInstance();
        },
    },
    data () {
        return {
            isActive: false,
        };
    },
    created() {
        this.setTab();
    },
    methods: {
        setTab() {
            const { registerTab } = this.tabsInstance;

            if (registerTab) {
                registerTab(this);
            }
        },
    },
};
</script>

<style lang="scss">
.ui-vertical-tab {
    color: $general-black;

    &__title {
        color: $general-black;
        font-weight: 500;
        margin-bottom: 16px;
    }
}
</style>
