export default {
    getErrorMessage({ name }) {
        let message = 'An unexpected error occurred, try refreshing the page.';

        if (name === 'NotFoundError' || name === 'DevicesNotFoundError') {
            message = 'A camera was not found on your device.';
        }

        if (name === 'NotReadableError' || name === 'TrackStartError') {
            message = 'Your camera is already in use for another application.';
        }

        if (name === 'OverconstrainedError' || name === 'ConstraintNotSatisfiedError') {
            message = 'Your camera does not support this functionality.';
        }

        if (name === 'NotAllowedError' || name === 'PermissionDeniedError') {
            message = 'You must provide access to your camera.';
        }
        
        return message;
    },
};
